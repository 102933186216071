/* footer */
.footerheading{
    font-family: Switzer-Bold;
    text-align: left;
    font-size: 13px;
    color: #141C26;
    opacity: 1;
    letter-spacing: 0px;
    line-height:24px;
  }
  .footer{
    text-align: left;
    font-size: 13px;
    color: #141C26;
    opacity: 1;
    letter-spacing: 0px;
    text-decoration: none;
    line-height:24px;
  }
  .footer:hover{
  color: #141C26 !important; 
  }
  .footercontent{
    font-family: Switzer-Regular;
    font-size: 13px;
    color: #141C26;
    opacity: 1;
    letter-spacing: 0px;
    text-decoration: none;
  }
  .footercontent:hover{
  color: #141C26 !important; 
  }
  .listcontent{
    font-size: 13px;
    color: #141C26;
    opacity: 1;
    letter-spacing: 0px;
    text-decoration: none;
    line-height: 28px;
  }
  .listcontent:hover{
  color: #141C26 !important; 
  }
  #googleplay{
  margin-left: -7px;
  }
  @media screen and (min-width: 768px) and (max-width: 1199px) {
  .listcontent{
    font-size: 11px;
  }
  }
  .mobilenum{
  display:none;
  }
  @media only screen and (max-width:358px){
  .mobilenum{
  display:block;
  }
  }
  .footerimg1{
    display:flex;
    justify-content: space-between;
  }
  .footerimg2{
    display:inline-block;
  }
  .dotlarge{
    /* vertical-align: text-top; */
    font-size: x-large;
  }
  .img{
    transition: transform .2s;
  }
  .img:hover{
    transform: scale(1.2);
  }
  /* footer */