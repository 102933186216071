
.roadmapFirst {
    margin-top: 20px;
   /* background: linear-gradient(180deg, #fff8ea 0%, #fff8eb 75%, #ffffff 100%); */
    padding: 10px;
  }
  
  .roadmapSecond {
    margin-top: 20px;
    background: linear-gradient(180deg, #ffffff 0%, #f8f9fa 100%);
    padding: 15px;
    box-shadow: 5px 5px 15px rgba(0, 0, 0, 0.2);
    border-radius: 10px;
   

  }
  
.roadmapSecond:hover{
z-index: 100;

}

  .roadmapSecond h5 {
    font-size: 18px;
    margin-bottom: 10px;
    color: #0d436c;
  }
  
  .roadmapSecond p {
    font-size: 16px;
    color: #555;
    text-align: justify;
    line-height: 22px;
    overflow: hidden;
    display: -webkit-box;
    -webkit-line-clamp: 2;
    -webkit-box-orient: vertical;
    
  }

  
.roadmapSecond .expanded {
  -webkit-line-clamp: unset; /* Remove line clamp for expanded view */
  overflow: unset;
  padding:0px 7px
 
    
}

.roadmapSecond .collapsed {
  -webkit-line-clamp: 2;  /* Keep the 2 lines limit when collapsed */
  
}

 

  
  .roadmapSecond span.text-primary {
    font-size: 12px;
    cursor: pointer;
    display: block;
    text-align: right;
  }
  .iconimg{
    display: grid;
    place-items: center;
    width: 100%;
  }
  .sectiontitle {
    font-family: Switzer-Bold;
    font-size: 28px;
    line-height: 48px;
    letter-spacing: 0px;
  }
  .readmore{
    color: #e01313;
  }

  @media (max-width: 768px) {
    .sectiontitle {
      font-family: Switzer-Bold;
      font-size: 5vw;
      letter-spacing: 0px;
    }
  
  }