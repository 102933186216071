.scrollcontent {
  font-size: 16px;
  color: #fff;
  line-height: 24px;
  letter-spacing: 0px;
}
.whyshankarcont{
  font-size: 18px;
  line-height: 26px;
  letter-spacing: 0;
  color: #ffffff;
}
.lottiescroll {
  background: #db1b0d;
  border-radius: 5px;
}
.headerbuttonalink {
  color: #fff;
  text-decoration: none;
}
.headerbuttonalink:hover {
  color: #fff;
}
.lookingForOnlineClass {
  width: 746px !important;
  height: 311px !important;
}
@media only screen and (max-width: 500px) {
  .scrollcontent {
    font-size: 12px;
  }
  .sectiontitle {
    font-size: 18px !important;
    line-height: 32px !important;
  }
  .sectionsubtitle {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .acheivername, .whyshankarcont{
    font-size: 13px !important;
    line-height: 24px !important;
  }
  .acheiverrank {
    font-size: 13px !important;
    line-height: 24px !important;
  }
}
/* Carousel */
@media only screen and (min-width: 768px) and (max-width: 1017px) {
  #leftcarousel {
    left: -27px !important;
  }
  #rightcarousel {
    right: -27px !important;
  }
}
.carouselarrowright {
  background: url("../../assets/images/home/right.svg") !important;
  width: 3rem;
}
.carouselarrowleft {
  background: url("../../assets/images/home/left.svg") !important;
  width: 3rem;
}
.carouseltarget [data-bs-target] {
  width: 13px;
  height: 13px;
  border-radius: 50%;
  background-color: #141c2673;
}
.coursesimg {
  background-image: url("../../assets/images/home/Back.webp");
  background-repeat: no-repeat;
  background-size: 100% 100%;
  transition: 0.6s ease-in-out;
}
.courseknowmore {
  color: #db1b0d;
  text-decoration: underline !important;
}
.courseknowmore:hover {
  color: #141c26;
  text-decoration: underline !important;
}
.courseshover {
  text-decoration: none !important;
  color: #141c26;
}
.courseshover:hover {
  text-decoration: none !important;
  color: #db1b0d !important;
}
.fonthover {
  transition: 0.6s ease-in-out;
}
.line {
  width: 120px;
  height: 6px;
  background: #63d19e;
}
@media only screen and (max-width: 500px) {
  .sectiontitle {
    font-size: 18px !important;
    line-height: 32px !important;
  }
  .acheiverrank {
    font-size: 13px !important;
    line-height: 24px !important;
  }
  .locationid {
    font-size: 13px !important;
    line-height: 24px !important;
  }
  .normalbutton {
    font-size: 13px;
  }
  .eventbutton {
    font-size: 13px;
  }
  .ourachievementcontent {
    font-size: 32px !important;
    line-height: 21px !important;
  }
  .acheivername {
    font-size: 13px !important;
    line-height: 24px !important;
  }
  .viewbutton {
    font-size: 13px;
    width: 107px;
  }
  .sectionsubtitle {
    font-size: 16px !important;
    line-height: 30px !important;
  }
  .normalbutton1 {
    font-size: 13px;
  }
}
.sectiontitle {
  font-family: Switzer-Bold;
  font-size: 28px;
  line-height: 48px;
  letter-spacing: 0px;
}
.sectiontitle1{
  font-family: Switzer-Bold;
  font-size: 22px;
  line-height: 48px;
  letter-spacing: 0px;

}
.sectionsubtitle {
  font-size: 21px;
  line-height: 36px;
  letter-spacing: 0px;
}
.acheiverrank {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}

.acheiverrankhome {
  text-align: justify;
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}
.courseshover2 {
  color: #db1b0d;
}
.courseshover2:hover,
.courseshover2:focus{
  color: #141c26;
}
.locationid {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
}
.couresesimg1 {
  background-image: url("../../assets/images/home/locate.webp");
  background-repeat: no-repeat;
  background-size: 100% 90%;
}
.normalbutton {
  height: 42px;
  width: 143px;
  color: #fff;
  border: none;
  background: #db1b0d;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: inset 0 0 0 0 rgb(219, 27, 13);
  transition: box-shadow 0.3s ease;
}

.eventbutton {
  height: 37px;
  width: 121px;
  color: #fff;
  border: none;
  background: #db1b0d;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: inset 0 0 0 0 rgb(219, 27, 13);
  transition: box-shadow 0.3s ease;
}
.normalbutton1 {
  height: 42px;
  width: 191px;
  color: #fff;
  border: none;
  background: #db1b0d;
  border-radius: 5px;
  font-size: 16px;
  box-shadow: inset 0 0 0 0 rgb(219, 27, 13);
  transition: box-shadow 0.3s ease;
}
.normalbutton:hover,
.normalbutton:focus,
.normalbutton1:hover,
.normalbutton1:focus {
  box-shadow: inset 0 -3.25em 0 0 rgb(174, 21, 10);
}
.cityid {
  color: #db1b0d !important;
}
@media only screen and (min-width: 992px) and (max-width: 1199px) {
  .locationid {
    font-size: 13px;
  }
}
.civilserviveheading {
  font-size: 13px;
  letter-spacing: 1.3px;
  line-height: 24px;
  color: #141c26;
  text-transform: uppercase;
  opacity: 1;
}
.bground {
  background: transparent
    linear-gradient(180deg, #fff8ea 0%, #fff8eb 75%, #ffffff 100%) 0% 0%
    no-repeat padding-box;
  opacity: 1;
}
.ourachievementcontent {
  font-family: Switzer-Bold;
  text-align: center;
  font-size: 48px;
  letter-spacing: 0px;
  line-height: 24px;
  color: #db1b0d;
  opacity: 1;
}
.acheivername {
  font-size: 16px;
  line-height: 24px;
  letter-spacing: 0;
  font-family: Switzer-Bold;
}
.viewbuttonalink {
  text-decoration: none;
  color: #db1b0d;
}
/* .viewbuttonalink:hover {
  color: #db1b0d;
} */
.viewbutton {
  font-size: 16px;
  color: #db1b0d;
  letter-spacing: 0px;
  line-height: 24px;
  border-radius: 5px;
  background: none;
  border: 1px solid #db1b0d;
  width: 143px;
  height: 42px;
  box-shadow: inset 0 0 0 0 rgb(219, 27, 13);
  transition: box-shadow 0.3s ease;
}
.viewbutton:hover,
.viewbutton:focus{
  box-shadow: inset 12em 0 0 0 rgb(219, 27, 13);
  color: #fff;
}
@media only screen and (min-width: 1400px) {
  .blogid {
    line-height: 30px;
  }
}
.scrollparent {
  white-space: nowrap;
  display: flex;
  justify-content: space-between;
}
@media only screen and (max-width: 991px) {
  .scrollparent {
    overflow-x: scroll;
    overflow-y: hidden;
  }
}
.scrolldiv {
  display: inline-block;
}
.formstar {
  color: #db1b0d;
}
.facultyborder3 {
  background: #f4f6f8;
}
.marquee {
  white-space: nowrap;
  overflow: hidden;
  box-sizing: border-box;
}
.marquee p {
  display: inline-block;
  padding-left: 100%;
  animation: marquee 25s linear infinite;
}
@keyframes marquee {
  0% {
    transform: translate(0, 0);
  }
  100% {
    transform: translate(-100%, 0);
  }
}
.marquee p:hover{
  animation-play-state: paused;
}
.studentimage {
  position: relative !important;
}
.studentvideo {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}
.playSize{
  width: 30px;
  height: 24px;
}
.bg{
    background: #F4F6F8 0% 0% no-repeat padding-box;
}
.announcement{
  font-size:18px;
  font-family: Switzer-Bold;
  line-height: 24px;
  letter-spacing: 0px;
}

@media only screen and (max-width:500px){
  .announcement{
    font-size:16px;
  }
  .announcementlink{
    font-size: 11px !important;
    line-height: 20px !important;
  }
}
.announcementlink, .announcementlink:hover{
  font-size:13px;
  line-height: 24px;
  letter-spacing: 0px;
  color:#DB1B0D;
  text-decoration: underline !important;
}

@media only screen and (min-width:768px){
  .nexticon{
    background-image:url("../../assets/images/home/right.svg") !important;
    position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      padding: 0;
      text-align: center;
      background: 0 0;
      border: 0;
      opacity: 1 !important;
      transition: opacity .15s ease;
      background-repeat: no-repeat;
      background-position-y: center;
      right: 0;
  }
  .previcon{
    background-image:url("../../assets/images/home/left.svg") !important;
    position: absolute;
      top: 0;
      bottom: 0;
      z-index: 1;
      display: flex;
      align-items: center;
      justify-content: center;
      width: 3rem;
      padding: 0;
      text-align: center;
      background: 0 0;
      border: 0;
      opacity: 1 !important;
      transition: opacity .15s ease;
      background-repeat: no-repeat;
      background-position-y: center;
      left:0;
  }
}
@media only screen and (max-width:767px){
  .nexticon{
    background-image:none !important;
  }
  .previcon{
    background-image:none !important;
  }
}
@media only screen and (max-width:768px){
  .lookingForOnlineClass {
    width: 715px !important;
  }
}
.captcha div div {
  width: auto !important;
  height: auto !important;
}
@media screen and (max-width: 416px){
  .captcha{
  transform: scale(0.90);
  transform-origin: 0 0;
}
}
@media screen and (max-width: 375px){
  .captcha{
  transform: scale(0.86);
  transform-origin: 0 0;
}
}
@media only screen and (max-width:374px){
  .captcha{
    transform: scale(0.68);
    transform-origin: 0 0;
  }
  .captcha div div {
    height: 65px !important;
  }
}
.alerttext{
  color: #DB1B0D;
}

.centerContent {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  text-align: center;
  width: 100%;
}

.roadmapStart {
  position: relative;
  width: 100%;
  padding: 20px;
  
  
  text-align: center;
  transition: transform 0.3s ease, box-shadow 0.3s ease; /* Fixed transition syntax */
  /* Add perspective for 3D effect */
  transform-style: preserve-3d;

}

.roadmapStart p {
  font-size: 1em;
 
  text-align: justify;
  line-height: 28px;
}

.roadmapStart p span{
  
  color: #d61919;
  line-height: 28px;
}
.whyshankarcontnew{
  font-size: 16px;
  line-height: 26px;
  letter-spacing: 0;
  color: #555;
  text-align: justify;
}